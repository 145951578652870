import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Logo from "assets/images/fitfarm-logo-for-white-banner.svg"

const CrossFitIntro = (props) => {
  return (
    <>
      <section className="container container-white">
        <div className="img-section__container img-section__container-r">
          <Img
            fluid={props.sectionImage.childImageSharp.fluid}
            alt="A man sitting on a weight bench getting ready to perform a bench press"
            className="img-section__container-r__img"
          />
        </div>
        <div className="rectangle-header rectangle-header__green"></div>
        <div className="skew-header skew-header__green skew-header__green-left"></div>

        <h2 className=" type-heavy type-black type-header__primary type-header__primary-l">
          <span className="type-emph type-white type-break__after">Omaha</span>{" "}
          CrossFit
        </h2>
        <div className="container-type__white">
          <h3 className="type type-heavy type-black type-h3">
            Start with a Free Intro
          </h3>
          <p className="type-reg type-black type-body">
            Your first month at FitFarm focuses on evaluating your strength,
            establishing goals, and learning the fundamentals. Whether you’re an
            elite athlete or live with health limitations our Omaha CrossFit
            Classes are tailored for you.
          </p>
          <h3 className="type type-heavy type-black type-h3">
            CrossFit Coaches
          </h3>
          <p className="type-reg type-black type-body u-mt-1">
            Our trainers are here to challenge you and keep you safe. The
            coaches will adjust the movements in the workout to increase or
            decrease the difficulty. They monitor your strength and will ask you
            to lift more or less weight. They will ask you to move slower or
            faster. The coaches train this way to improve your fitness and
            safety.
          </p>
          <h3 className="type type-heavy type-black type-h3">
            CrossFit Classes
          </h3>
          <p className="type-reg type-black type-body u-mt-1">
            Classes are for everyone, no matter strength or stamina. Belonging
            to a community is important to your health. You will be training
            with athletes and first-timers. Training like this improves
            everyone’s workouts, because you’re pushing each other over the
            finish line.
          </p>
          <h3 className="type type-heavy type-black type-h3">Schedule</h3>
          <p className="type-reg type-black type-body u-mt-1">
            Omaha CrossFit classes are available Monday through Friday, 5:00am
            to 6:30pm. On Saturdays, classes are in the morning and Sundays are
            open gym. You can view our entire{" "}
            <a
              href="https://app.wodify.com/Schedule/PublicCalendarListView.aspx?tenant=4901"
              className="type-heavy type-green"
              target="__blank"
              rel="external"
            >
              class schedule here.
            </a>
          </p>
          <h3 className="type type-heavy type-black type-h3">
            Our Membership options include:{" "}
          </h3>

          <ul className="u-mt-2">
            <li className="type-reg type-black type-body li li-green">
              Yearly
            </li>
            <li className="type-reg type-black type-body li li-green">
              Monthly
            </li>
            <li className="type-reg type-black type-body li li-green">
              Weekly
            </li>
            <li className="type-reg type-black type-body li li-green">
              Drop-Ins are welcome!
            </li>
          </ul>
          <div className="u-mt-2">
            <Link
              to="/contact-us"
              className="btn btn-primary btn-primary__black type-heavy type-black u-mt-1"
            >
              I want to do CrossFit
            </Link>
          </div>
        </div>
        <img
          src={Logo}
          className="img-background img-background__white"
          alt="FitFarm Logo reversed nearly transparent"
        />
      </section>
    </>
  )
}

export default CrossFitIntro
