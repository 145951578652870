import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "components/Layout"
import CrossFitBanner from "components/Banners/CrossFitBanner"
import WhatIsCrossFit from "components/WhatIsCrossFit"
import CrossFitIntro from "components/CrossFitIntro"
import GymLayoutTwo from "components/GymLayoutTwo"
import CrossFitCommunity from "components/CrossFitCommunity"
import Signup from "components/Signup"
import Seo from "components/Seo"
const getImages = graphql`
  {
    bannerCrossFit: file(
      relativePath: { eq: "fitfarm-omaha-crossfit-classes-banner.webp" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    whatIsCrossFit: file(
      relativePath: { eq: "fitfarm-omaha-crossfit-classes-for-export-2.webp" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    crossFitIntro: file(relativePath: { eq: "fitfarm-omaha-crossfit-8.webp" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    omahaGyms: file(
      relativePath: { eq: "fitfarm-omaha-crossfit-classes-for-export-4.webp" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    community: file(
      relativePath: { eq: "fitfarm-omaha-gyms-community-bg.webp" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    signup: file(
      relativePath: { eq: "fitfarm-midtown-gym-omaha-interior.png" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const CrossFit = () => {
  const imgData = useStaticQuery(getImages)

  return (
    <Layout>
      <Seo
        title="Ready to Grow? CrossFit Omaha Gym"
        description="CrossFit is an awesome way to improve your health and fitness. We offer classes all week long and have two different location. Drop-ins welcome!"
        keywords={["omaha crossfit", "crossfit", "crossfit omaha", "fitfarm"]}
        pathname={"/crossfit/"}
      />
      <article>
        <CrossFitBanner leftImage={imgData.bannerCrossFit} />
        <WhatIsCrossFit sectionImage={imgData.whatIsCrossFit} />
        <CrossFitIntro sectionImage={imgData.crossFitIntro} />
        <GymLayoutTwo sectionImage={imgData.omahaGyms} />
        <CrossFitCommunity sectionImage={imgData.community} />
        <Signup sectionImage={imgData.signup} />
      </article>
    </Layout>
  )
}

export default CrossFit
